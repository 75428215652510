.howListContainer {
  display: flex;
  gap: 20px;
}

.howListItemColumn {
  width: 210px;
  margin-left: 10px;
}

.usedColumn {
  width: 60px;
}
.usedByColumn {
  width: 60px;
  margin-left: 10px;
}

@media only screen and (max-width: 870px) {
  .howListContainer {
    gap: 10px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .howListContainer {
    gap: 5px;
    flex-direction: column;
  }
}
