.container {
  background-color: #404040;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theFormContainer {
  background-color: #fff;
  width: 20%;
  padding: 20px;
  border-radius: 1rem;
  box-shadow: rgba(167, 167, 184, 0.688) 0px 50px 100px -20px,
    rgba(40, 34, 34, 0.3) 0px 30px 60px -30px;
}

.title {
  margin: 0px;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1080px) {
  .theFormContainer {
    width: 40%;
  }
}

@media only screen and (max-width: 480px) {
  .theFormContainer {
    width: 90%;
  }
}
