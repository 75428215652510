.child {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
}

.child:nth-child(even) {
  background-color: transparent;
}

.child:nth-child(odd) {
  background-color: #f2f2f2;
}

.selectTag {
  min-width: 150px;
}
