.containerOne {
  /*  */
}

.containerOne p {
  margin: 0;
}

.label {
  font-size: 1rem;
  color: #ababab;
  margin-top: 15px !important;
}

.questionnaireItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.question {
  margin-right: 0.5em;
}

.answer {
  font-weight: bold;
  flex-grow: 1;
}
